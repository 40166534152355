<template>
    <main class="" :class="{ 'container-slim': lgAndUp || xlAndUp }">
        <div class="container-fluid  mt-3 ">
            <div class="row flex-nowrap justify-content-between ">

                <div class="col-md-2 col-12 border  bg-white rounded d-flex  align-items-center h-100 ">
                    <div class="row flex-nowrap  justify-content-between mt-2">
                        <div class="dashb-icon  col-md-9 mx-0 px-0  ">
                            <div class="row flex-nowrap justify-content-between    ">
                                <div class="col-md-3">
                                    <img :src="churchLogo" alt="" v-if="churchLogo" class="">
                                    <img v-else src="../../assets/mobileonboarding/avatar-male.png" class="" alt="" />
                                </div>
                                <div class="col-md-9 font-weight-bold overflow-auto  " style="height: 2.5rem;">
                                    <div class="small  ">Welcome back</div>
                                    <div>{{ tenantName }}</div>
                                </div>


                            </div>
                        </div>
                        <!-- <div class=" px-0   "> -->
                        <div class=" col-md-3 ">
                            <el-dropdown class=" w-100   ">
                                <div class="col-md-2 px-0 mt-1  "><el-icon :size="25"
                                        class="el-icon--right "><arrow-down /></el-icon></div>

                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item>Account Settings</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>

                            </el-dropdown>
                        </div>


                    </div>
                </div>
                <div class="col-md-8">
                    <el-input v-model="searchInput" class="w-100 " size="large" placeholder="Search"
                        :prefix-icon="Search" />
                </div>
                <div class="col-md-1 col-2 d-flex h-100 mt-2 ">
                    <div class="d-flex align-items-center" @click="showNotification">
                        <el-icon :size="30">
                            <Bell />
                        </el-icon>
                    </div>
                    <span class="notification"></span>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <el-drawer style="background-color: #EEEEEE;" size="45%" v-model="notificationDrawer" title="" direction="rtl">
                <div class="row ">
                    <div class="col-md-11 d-flex">
                        <div class="d-flex">
                            <el-icon :size="22">
                                <Bell />
                            </el-icon>
                            <span class="notification"></span>
                        </div>
                        <span class="font-weight-bold h5 pl-2">Notification(s)</span>
                    </div>
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-md-11  d-flex justify-content-between rounded py-2 " style="background-color: #FBFBFB;">
                        <span class="font-weight-bold">2,500 UUnits of SMS Received </span>
                        <span class="">Today</span>
                    </div>
                    <div class="col-md-11 d-flex justify-content-between rounded py-2 mt-2 "
                        style="background-color: #FBFBFB;">
                        <router-link to="/tenant/units" class="text-decoration-none text-dark">
                            <span class="font-weight-bold">SMS Failed to send due to Low Unit.....
                                <span class="font-italic primary--text">Buy Unit now</span> </span>
                        </router-link>

                        <span class="">Yesterday</span>
                    </div>
                    <div class="col-md-11 d-flex justify-content-between rounded py-2 mt-2"
                        style="background-color: #FBFBFB;">
                        <span class="font-weight-bold">4,300 Units of SMS Received </span>
                        <span class="">Yesterday</span>
                    </div>
                    <div class="col-md-11 d-flex justify-content-between rounded py-2 mt-2 "
                        style="background-color: #FBFBFB;">
                        <span class="font-weight-bold">1,500 Units of SMS Received </span>
                        <span class="">1/11/22</span>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center  align-items-center" style="height: 16rem;">
                        <el-icon style="color: #AD3333" :size="22">
                            <Delete />
                        </el-icon>
                        <span class="pl-2">Clear All</span>
                    </div>
                </div>

            </el-drawer>
        </div>

        <div class="container-fluid  px-0 mt-4">
            <div class="row">
                <div class="col-md-12 h5 mt-4 font-weight-bold">
                    Start New Campaign
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-6 col-12 mt-4 font-weight-bold ">
                    <router-link to="/tenant/sms/sent" class="text-decoration-none text-dark">
                        <div class=" bg-white the-card " style="height: 9rem;">
                            <div class="row justify-content-center 
                         h-100 align-items-center">
                                <div class="col-md-12 d-flex justify-content-center ">
                                    <div class="rounded-border d-flex align-items-center justify-content-center ">
                                        <img class=" " src="../../assets/sms-icon.png" alt="">
                                    </div>
                                    <div class="mt-3 ml-2">Send SMS</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-sm-6 col-12 mt-4 font-weight-bold">
                    <router-link to="/tenant/voice/voicelist" class="text-decoration-none text-dark">
                        <div class=" bg-white the-card1 " style="height: 9rem;">
                            <div class="row justify-content-center 
                         h-100 align-items-center">
                                <div class="col-md-12 d-flex justify-content-center">
                                    <div class="rounded-border  d-flex align-items-center justify-content-center">
                                        <img class=" " src="../../assets/voice-icon.png" alt="">
                                    </div>
                                    <div class="mt-3 ml-2">Send Voice</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-sm-6 col-12 mt-4 font-weight-bold">
                    <router-link to="/tenant/email" class="text-decoration-none text-dark">
                        <div class=" bg-white the-card2  " style="height: 9rem;">
                            <div class="row justify-content-center 
                         h-100 align-items-center">
                                <div class="col-md-12 d-flex justify-content-center">
                                    <div class="rounded-border d-flex align-items-center justify-content-center ">
                                        <img class=" " src="../../assets/email-icon.png" alt="">
                                    </div>
                                    <div class="mt-3 ml-2">Send Email</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-3 col-sm-6 col-12 mt-4 font-weight-bold">
                    <router-link to="/tenant/whatsapp/auth" class="text-decoration-none text-dark">
                        <div class=" bg-white the-card3  " style="height: 9rem;">
                            <div class="row justify-content-center 
                         h-100 align-items-center">
                                <div class="col-md-12 d-flex justify-content-center">
                                    <div class=" rounded-border d-flex align-items-center justify-content-center">
                                        <img class="" src="../../assets/whatsapp-icon.png" alt="">
                                    </div>
                                    <div class="mt-3 ml-2">Send Whatsapp</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container-fluid mt-4 " v-if="dashboardDetail.smsUnit <= 0">
            <div class="row ">
                <div class="col-md-12 rounded-unit bg-white  ">
                    <div class="row">
                        <div class="col-md-6 font-weight-bold d-flex mt-2 ">
                            <div>
                                <el-icon :size="30">
                                    <ChatDotSquare />
                                </el-icon>
                            </div>
                            <div class="ml-2 mt-1">You have <span class="h5 font-weight-bold">
                                    {{ dashboardDetail.smsUnit }}</span> units of SMS
                                remaining</div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-end mt-2">
                            <router-link to="/tenant/units"
                                class="text-decoration-none text-white w-100 d-flex justify-content-end">
                                <el-button class=" text-white  btn-color  mb-2">Buy
                                    Units</el-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid px-0 mt-4 ">
            <div class="row justify-content-between">
                <div class="col-md-7 col-12   ">
                    <div class="bg-white pb-4 rounded-lg text-dark col-md-12 mt-2 "
                        style="box-shadow: 2px 4px 10px 0px #0000000F;">
                        <div class="row">
                            <div class="col-md-12 mt-3 ml-3 font-weight-bold">Recent Messages</div>
                            <div class="col-md-12 mt-3 ml-3 ">
                                <Table :data="searchMessages" :headers="contactedHeaders" :checkMultipleItem="false"
                                    v-loading="loading">
                                    <template v-slot:senderId="{ item }">
                                        <!-- <div class=""> -->
                                        <!-- <div>
                                                <el-card shadow="hover" class="c-pointer person-image"
                                                    style="border-radius: 50%; height: 26px; width: 26px">
                                                    <img style="border-radius: 50%; height: 30px; width: 30px"
                                                        src="../../assets/best-Copy.jpg" alt="">
                                                </el-card>
                                            </div> -->
                                        <div class="c-pointer ml-1 font-weight-600">
                                            {{ item.senderID }}
                                        </div>
                                        <!-- </div> -->
                                    </template>
                                    <template v-slot:channel="{ item }">
                                        <div class="c-pointer font-weight-600">
                                            {{ item.channel }}
                                        </div>
                                    </template>
                                    <template v-slot:unitUsed="{ item }">
                                        <div class="c-pointer font-weight-600">
                                            {{ item.units }}
                                        </div>
                                    </template>
                                    <!-- <template v-slot:status="{ item }">
                                        <div class="c-pointer">
                                            {{ item.status }}
                                        </div>
                                    </template> -->
                                    <template v-slot:date="{ item }">
                                        <div class="c-pointer font-weight-600">
                                            {{ date(item.date) }}
                                        </div>
                                    </template>
                                    <!-- <template v-slot:report="{ item }">
                                        <div>
                                            <router-link :to="{
                                                name: 'DeliveryReport',
                                                params: { messageId: item.id },
                                                query: { units: item.units },
                                            }" class="small-text no-decoration primary--text">View report</router-link>
                                        </div>
                                    </template> -->
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-12 mt-3 mt-sm-0 ">
                    <div class=" col-md-12  text-dark mt-2">
                        <div class="row ">
                            <div class="col-md-12  rounded-lg  d-flex align-items-center  text-white justify-content-between "
                                style="background-color: #124191; box-shadow: 2px 4px 10px 0px #0000000F;">
                                <div class="mt-2">
                                    <div class="small">SMS Units remainning</div>
                                    <div class="font-weight-bold">
                                        <h4>{{ dashboardDetail.smsUnit }}</h4>
                                    </div>
                                </div>
                                <div>
                                    <el-icon :size="30">
                                        <ChatDotSquare />
                                    </el-icon>
                                </div>
                            </div>
                            <div class="col-md-12 rounded-lg bg-white mt-3 p-3"
                                style="box-shadow: 2px 4px 10px 0px #0000000F;">
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-between">
                                        <div class=font-weight-bold> Channel Usage</div>
                                        <!-- <div>
                                            <el-dropdown trigger="click" class="w-100">
                                                <span class="el-dropdown-link w-100">
                                                    <div class="d-flex justify-content-between  text-secondary w-100"
                                                        size="large">
                                                        <span> Show: </span>
                                                        <span class="ml-1" style="color: #109CF1;">{{
                                                            selectedWeekly && selectedWeekly.name
                                                            ? selectedWeekly.name
                                                            : selectedWeekly
                                                        }}</span>
                                                        <div>
                                                            <el-icon class="el-icon--right">
                                                                <arrow-down />
                                                            </el-icon>
                                                        </div>
                                                    </div>
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item v-for="(itm, indx) in chartItemdropdown"
                                                            :key="indx" @click="selectedType1(itm)">{{
                                                                itm.name }}
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </div> -->
                                    </div>
                                    <!-- <div class="col-md-12">
                                        <hr class="w-100">
                                    </div> -->
                                    <div
                                        class="col-md-12 d-flex align-items-center justify-content-center mt-2 border border-bottom-0 border-left-0 border-right-0 ">
                                        <div class="col-md-12" v-if="donutOptions && donutOptions.labels.length > 0">
                                            <apexchart class="w-100" type="donut" :options="donutOptions"
                                                :series="chartDonutValue">
                                            </apexchart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 rounded-lg bg-white mt-3 p-3"
                                style="box-shadow: 2px 4px 10px 0px #0000000F;">
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-between">
                                        <div class=font-weight-bold> SMS Unit Usage</div>
                                        <!-- <div>
                                            <el-dropdown trigger="click" class="w-100">
                                                <span class="el-dropdown-link w-100">
                                                    <div class="d-flex justify-content-between  text-secondary w-100"
                                                        size="large">
                                                        <span> Show: </span>
                                                        <span class="ml-1" style="color: #109CF1;">{{
                                                            selectedMonthly && selectedMonthly.name
                                                            ? selectedMonthly.name
                                                            : selectedMonthly
                                                        }}</span>
                                                        <div>
                                                            <el-icon class="el-icon--right">
                                                                <arrow-down />
                                                            </el-icon>
                                                        </div>
                                                    </div>
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item v-for="(itm, indx) in chartItemdropdown2"
                                                            :key="indx" @click="selectedType2(itm)">{{
                                                                itm.name }}
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </div> -->
                                    </div>
                                    <!-- <div class="col-md-12">
                                        <hr class="w-100">
                                    </div> -->
                                    <div
                                        class="col-md-12 d-flex align-items-center justify-content-center mt-2 border border-bottom-0 border-left-0 border-right-0 ">
                                        <div class="w-100" v-if="areaOptions && areaOptions.xaxis.categories.length > 0">
                                            <apexchart class="w-100" type="area" :options="areaOptions"
                                                :series="seriesarea">
                                            </apexchart>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>


    </main>
</template>

<script>
import { computed, onMounted, ref, inject, watchEffect } from "vue";
import axios from "@/gateway/backendapi";
import store from "@/store/store";
// import ApexCharts from 'apexcharts'
import monthDayYear from "../../services/dates/dateformatter";
import { Search } from '@element-plus/icons-vue'
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import Table from "@/components/table/Table";
export default {
    components: {
        Search,
        Table
    },
    setup() {

        const searchInput = ref('')
        const loading = ref(false)
        const churchLogo = ref('')
        const notificationDrawer = ref(false)
        const tenantName = ref('')
        const tenantInfo = ref({})
        const dashboardDetail = ref({})
        const chartDonutValue = ref([])
        const chartAreaValue = ref([])
        const creditUsage = ref([])
        const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint()
        const selectedMonthly = ref("Monthly");
        const selectedWeekly = ref("Monthly");
        const contactedHeaders = ref([
            { name: "SENDER ID", value: "senderId" },
            { name: "CHANNEL", value: "channel" },
            { name: "UNIT USED", value: "unitUsed" },
            { name: "DATE", value: "date" },
            // { name: ' REPORT', value: 'report' },
        ]);
        const contactedList = ref([]);
        const channelUsge = ref([]);
        const donutOptions = ref({
            chart: {
                type: 'donut',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
        });

        const areaOptions = ref({
            chart: {
                height: 280,
                type: "area",
            },
            dataLabels: {
                enabled: false,
            },

            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                },
            },
            xaxis: {
                categories: [],
            },
        });

        const seriesarea = ref([{
            data: []
        }]);


        const donutSeries = computed(() => {
            if (!donutOptions.value && !dashboardDetail.value && donutOptions.value.labels.length <= 0) return "";
            return donutOptions.value;

        });

        const searchMessages = computed(() => {
            if (searchInput.value !== "" && contactedList.value.length > 0) {
                return contactedList.value.filter((i) => {
                    if (i.senderID)
                        return i.senderID
                            .toLowerCase()
                            .includes(searchInput.value.toLowerCase());
                });
            } else {
                return contactedList.value;
            }
        });

        const getDashboardDetail = async () => {
            loading.value = true;
            try {
                const { data } = await axios.get('/Dashboard/SendIt')
                console.log(data, 'hgfgg')
                dashboardDetail.value = data.returnObject,
                    contactedList.value = data.returnObject.last10Messages,
                    channelUsge.value = data.returnObject.channelUsage,
                    creditUsage.value = data.returnObject.creditUsageOverTime,
                    chartAreaValue.value = creditUsage.value.map((i) => i.value)
                areaOptions.value.xaxis.categories = creditUsage.value.map((i) => i.name)
                chartDonutValue.value = channelUsge.value.map((i) => i.value)
                donutOptions.value.labels = channelUsge.value.map((i) => i.name)
                seriesarea.value[0].data = creditUsage.value.map((i) => i.value)

                loading.value = false;

                // seriesarea.value = creditUsage.value

                // console.log(, 'ghhhh')
                // let newArray = []

                // console.log(newArray, 'kkkk')
            }
            catch (err) {
                console.log(err);
                loading.value = false;
            }
        }
        getDashboardDetail()





        const chartItemdropdown = ref([
            // { name: "Branches", id: 1 },
            { name: "Weekly", id: 2 },
            { name: "Monthly", id: 3 },
        ]);

        const chartItemdropdown2 = ref([
            // { name: "Branches", id: 1 },
            { name: "Weekly", id: 2 },
            { name: "Monthly", id: 3 },
        ]);
        const selectedType2 = (item) => {
            selectedMonthly.value = item;
        }
        const selectedType1 = (item) => {
            selectedWeekly.value = item;
        }

        const showNotification = () => {
            notificationDrawer.value = true
        }

        const date = (offDate) => {
            return monthDayYear.monthDayYear(offDate);
        };



        const getCurrentlySignedInUser = async () => {
            try {
                const res = await axios.get("/api/Membership/GetCurrentSignedInUser");
                tenantInfo.value = res.data;
                const lastCharacters = tenantInfo.value.churchName.slice(-5);
                tenantName.value = tenantInfo.value.churchName.replace(lastCharacters, "...");
                getChurchProfile()
            } catch (err) {
                console.log(err);
            }
        };

        getCurrentlySignedInUser();

        const getChurchProfile = async () => {
            try {
                let res = await axios.get(`/GetChurchProfileById?tenantId=${tenantInfo.value.tenantId}`)
                churchLogo.value = res.data.returnObject.logo
            }
            catch (err) {
                console.log(err)
            }
        }



        const getUser = computed(() => {
            if (!store.getters.currentUser || (store.getters.currentUser && Object.keys(store.getters.currentUser).length == 0)) return ''
            return store.getters.currentUser
        })
        // onMounted(() => {

        // });

        watchEffect(() => {
            if (getUser.value || tenantInfo.value) {
                tenantInfo.value = getUser.value;
                getChurchProfile()
                // emit('tenantname', tenantInfo.value)
            }
        })

        return {
            mdAndUp, lgAndUp, xlAndUp, Search, searchInput, contactedHeaders,
            loading,
            seriesarea,
            areaOptions,
            donutOptions,
            channelUsge,
            chartDonutValue,
            chartAreaValue,
            creditUsage,
            date,
            searchMessages,
            // searchContactedList,
            contactedList,
            tenantName,
            churchLogo,
            tenantInfo,
            showNotification,
            dashboardDetail,
            notificationDrawer,
            chartItemdropdown,
            chartItemdropdown2,
            selectedMonthly,
            selectedWeekly,
            selectedType1,
            donutSeries,
            selectedType2
        }
    },
}
</script>

<style scoped>
#main {
    display: flex;
    justify-content: space-between;
}

.btn-color {
    width: 140px;
    height: 40px;
    border-radius: 8px;
    background-color: #BB5852;
}

.the-card,
.the-card2,
.the-card3,
.the-card1 {
    border-radius: 0.5rem;
    box-shadow: 2px 4px 10px 0px #0000000F;
}

.the-card:hover {
    height: 9rem !important;
    background-color: #EEEEEE !important;
    color: black
}

.the-card1:hover {
    height: 9rem !important;
    background-color: #85B3FF !important;
    color: white !important
}

.the-card2:hover {
    height: 9rem !important;
    background-color: #E5A864 !important;
    color: white !important
}

.the-card3:hover {
    height: 9rem !important;
    background-color: #4CC85B !important;
    color: white !important
}

.dashb-icon img {
    width: 30px;
}

.rounded-border {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: #F2F6FA;
}

.notification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
}

.rounded-unit {
    border: 1px solid #BB5852;
    box-shadow: 2px 4px 10px 0px #0000000F;
    border-radius: 30px;
    color: #BB5852;
}
</style>